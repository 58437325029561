import React, { Component } from "react";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import HtmlWrapper from "../components/HtmlWrapper";
import { GatsbyImage } from "gatsby-plugin-image";
import NewsForm from "../components/Contact/NewsForm";
import NewsUSP from "../components/USP/NewsUSP";
import Container from "../components/Container";

const ArticleImage = styled((props) => <GatsbyImage {...props} />)`
  ${tw`mt-20 sm:mt-0`}
`;

const Section = styled.section`
  ${tw`pb-32`}
`;

const Content = styled.div`
  ${tw`relative bg-black-800 mx-auto sm:-mt-40 pb-8 sm:py-24 sm:px-40 z-20`}
`;

const Title = styled.h1`
  ${tw`text-5xl sm:text-6xl font-semibold`}
`;

const Article = styled.article`
  ${tw`text-xl`}

  & p {
    ${tw`leading-normal`}
  }

  & iframe {
    ${tw`my-8`}
  }

  & h1,
  h2,
  h3,
  h4,
  h5 {
    ${tw`font-semibold mt-16`}
  }

  & a {
    ${tw`text-primary`}
  }

  & blockquote {
    ${tw`px-24`}
  }
`;

export default class Post extends Component {
  render() {
    const data = this.props.data;
    const PostData = data.wp.post;

    return (
      <HtmlWrapper seo={PostData}>
        <ArticleImage
          image={
            data.wp.post.featuredImage.imageFile.childImageSharp.gatsbyImageData
          }
          alt={data.wp.post.featuredImage.altText}
        />
        <Section>
          <Container>
            <Content>
              <Title>{data.wp.post.title}</Title>
              <NewsUSP text="Har du spørgsmål? Det koster ikke noget at spørge..." />
              <Article
                dangerouslySetInnerHTML={{
                  __html: data.wp.post.content,
                }}
              />
            </Content>
            <NewsForm />
          </Container>
        </Section>
      </HtmlWrapper>
    );
  }
}

export const query = graphql`query PostQuery($id: ID!) {
  wp {
    post(id: $id) {
      title
      content
      link
      ...YoastPost
      categories {
        nodes {
          name
        }
      }
      featuredImage {
        sourceUrl
        altText
        imageFile {
          childImageSharp {
            gatsbyImageData(
              quality: 100
              transformOptions: {cropFocus: ATTENTION, grayscale: true}
              layout: FULL_WIDTH
            )
          }
        }
      }
    }
  }
}
`;
