import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

const CallToAction = styled.div`
  ${tw`mt-4 md:mt-8 lg:mt-12`}
`

const LinkTitle = styled.div`
  ${tw`text-xl`}
`

const StyledLink = styled.a`
  ${tw`text-white inline-block relative no-underline mt-2`}

  &:hover:before {
    ${tw`w-full`}
    transform: scale3d(1, 1, 1);
    transition: transform 0.25s 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94),
      -webkit-transform 0.25s 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  &:hover:after {
    ${tw`w-0`}
    transition: width 0.25s 0s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }

  &:before,
  &:after {
    ${tw`bg-primary`}
  }

  &:before {
    ${tw`bg-primary absolute bottom-0 left-0 block w-full z-10`}
    content: "";
    height: 5px;
    transform: scale3d(0, 1, 1);
    transform-origin: 0 1px;
    transition: transform 0.25s 0s cubic-bezier(0.55, 0.085, 0.68, 0.53),
      -webkit-transform 0.25s 0s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }

  &:after {
    ${tw`bg-primary absolute bottom-0 right-0 block w-full`}
    content: "";
    height: 2px;
    transition: width 0.25s 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
`

const LinkText = styled.span`
  ${tw`inline-flex items-center relative z-10`}
`

function ContactAction({ heading, text, link }) {
  return (
    <CallToAction>
      <LinkTitle>{heading}</LinkTitle>
      <StyledLink href={link}>
        <LinkText>{text}</LinkText>
      </StyledLink>
    </CallToAction>
  );
}

export default ContactAction;
