import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import axios from "axios";
import ContactData from "./ContactData";
import TextField from "@material-ui/core/TextField";

const FormContainer = styled.form`
  ${tw`px-4 -mx-4 sm:px-32 py-8 sm:py-24 bg-black-700`}
`;

const Content = styled.div`
  ${tw`flex flex-col sm:flex-row`}
`;

const ContentWrapper = styled.div`
  ${tw`flex-1`}
`;

const Title = styled.h2`
  ${tw`text-5xl font-semibold text-left sm:text-center`}
`;

const InputWrapper = styled.div`
  ${tw`w-full mt-8`}

  & div {
    ${tw`w-full`}
  }
`;

const DescFieldWrapper = styled.div`
  ${(props) => (props.isVisible === true ? tw`mb-8` : tw`hidden`)}

  & div {
    ${tw`w-full`}
  }
`;

const RespondReminder = styled.div`
  ${tw`mt-8 text-center text-black-300`}
`;

function NewsForm({ artist }) {
  const [visible, setVisible] = useState(false);
  const { control, handleSubmit } = useForm();

  const toggleField = () => {
    setVisible(!visible);
  };

  const onSubmit = (data) => {
    axios
      .post("https://formcarry.com/s/GXr0KacAusQ", data, {
        headers: { Accept: "application/json" },
      })
      .then((response) => {
        if (response.status === 200) {
          window.location.href = "https://djbooking.dk/besked-modtaget-nyhed/";
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <FormContainer id="contact-form">
      <Title>Send forespørgsel</Title>
      <Content>
        <ContentWrapper>
          <ContactData />
        </ContentWrapper>
        <ContentWrapper>
          <form onSubmit={handleSubmit(onSubmit)} acceptCharset="UTF-8">
            <InputWrapper>
              <Controller
                as={<TextField />}
                required
                type="text"
                name="artist"
                control={control}
                label="Navn på artist"
                variant="outlined"
              />
            </InputWrapper>
            <InputWrapper>
              <Controller
                as={<TextField />}
                required
                type="text"
                name="navn"
                control={control}
                label="Navn eller firma"
                variant="outlined"
              />
            </InputWrapper>
            <InputWrapper>
              <Controller
                as={<TextField />}
                required
                type="email"
                name="email"
                control={control}
                label="E-mail"
                variant="outlined"
              />
            </InputWrapper>
            <InputWrapper>
              <Controller
                as={<TextField />}
                required
                type="tel"
                name="telefon"
                control={control}
                label="Telefon"
                variant="outlined"
              />
            </InputWrapper>
            <InputWrapper>
              <p onClick={toggleField}>
                {visible ? "-" : "+"} Tilføj en bemærkning (Valgfri)
              </p>
            </InputWrapper>
            <DescFieldWrapper isVisible={visible}>
              <Controller
                as={<TextField />}
                multiline
                rows="4"
                name="beskrivelse"
                control={control}
                label="Beskrivelse af arrangement"
                placeholder="Evt. en kort beskrivelse af dit arrangement, dato, sted m.m..."
                variant="outlined"
              />
            </DescFieldWrapper>
            <input type="hidden" name="_gotcha"></input>
            <button
              type="submit"
              className="btn btn__large btn__primary u__full"
            >
              Send forespørgsel
            </button>
            <RespondReminder>
              Vi svarer på hverdage indenfor 24 timer
            </RespondReminder>
          </form>
        </ContentWrapper>
      </Content>
    </FormContainer>
  );
}

export default NewsForm;
