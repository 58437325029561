import React from "react";
import { keyframes } from "@emotion/core";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import Arrow from "../Links/LinkArrow";
import { Link } from "react-scroll";

const Container = styled.div`
  ${tw`flex flex-1 flex-wrap flex-col sm:flex-row mb-12 p-8 sm:py-8 sm:px-16 bg-primary rounded items-center`}
`;

const Content = styled.div`
  ${tw`w-full sm:w-1/2`}
  ${props => (props.center === "true" ? tw`text-left sm:text-center` : tw``)}
`;

const Heading = styled.p`
  ${tw`text-xl sm:text-2xl text-black-900 font-bold`}
`;

const pulse = keyframes`
  to {
    box-shadow: 0 0 0 18px rgba(39, 193, 125, 0);
  }
`;

const LinkToFormular = styled(props => (
  <Link {...props}>
    {props.children} <Arrow />
  </Link>
))`
  ${tw`cursor-pointer inline-block text-center align-middle fill-current text-black-900 border border-solid border-black-900 select-none py-3 px-5 font-bold rounded w-full sm:w-auto`}
  font-family: Graphik, "DJB-Text", sans-serif;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  animation: ${pulse} 1.5s infinite cubic-bezier(0.66, 0, 0, 1);

  &:hover > svg {
    transform: translateX(4px);
  }
`;

const NewsUSP = props => {
  return (
    <Container>
      <Content>
        <Heading>{props.text}</Heading>
      </Content>
      <Content center="true">
        <LinkToFormular
          to="contact-form"
          spy={true}
          smooth={true}
          duration={500}
          offset={-140}
        >
          Send forespørgsel
        </LinkToFormular>
      </Content>
    </Container>
  );
};

export default NewsUSP;
