import React from "react";
import styled from "@emotion/styled";
import tw from "tailwind.macro";
import Media from "react-media";
import ContactAction from "./ContactAction";
import businessData from "./business";

const Description = styled.div`
  ${tw`lg:pr-32 mt-10`}
`;

const Contact = styled.div`
  ${tw`flex justify-around sm:block`}
`;

function ContactData() {
  const { telephone, email } = businessData;
  return (
    <React.Fragment>
      <Media query={{ minWidth: 640 }}>
        <Description>
          Vi står klar med <strong>professionel</strong> vejledning på baggrund
          af <strong>25</strong> års efaring.
        </Description>
      </Media>
      <Contact>
        <ContactAction
          heading="Ring til os"
          text="+45 7021 7025"
          link={`tel:${telephone}`}
        />
        <ContactAction
          heading="Send os en email"
          text="niels@djbooking.dk"
          link={`mailto:${email}`}
        />
      </Contact>
    </React.Fragment>
  );
}

export default ContactData;
